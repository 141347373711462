import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../component/spinner/Spinner"
import {GetUsersServices} from "../../../services/GetUsersServices";
import EmployeeLoggedView from "./EmployeeLoggedView"
import EmployeeNotLoggedView from './EmployeeNotLoggedView';

export default function HomeViewEmployees() {

    const [searchParams] = useSearchParams();
    const [getUser, setGetUser] = useState([]);
    const [loading, setLoading] = useState(true);

    const idSellerParams = searchParams.get ('idven');
    const userParams = searchParams.get ('idusr');
    const idSessionParams = searchParams.get ('idsession');
    
    useEffect(() => {
        const getUser = async () => {
            const userData = await GetUsersServices(userParams);
            setGetUser(userData);
            setLoading(false);
        };
        getUser();
    }, [userParams]);

    if (loading) {
        return <Spinner />; 
    }

    if (getUser[0]?.TxtError) {
        return (
            <div className="contenInit">
                <p>{getUser[0].TxtError}</p>
            </div>
        )
    }

    return (
        <div className='container containerApp'>
            <div className="row">
                <div className="col-12">
                    <div className='alignCenter marginTop'>
                        {idSessionParams === null ?
                            <EmployeeNotLoggedView users={getUser} /> :
                            <EmployeeLoggedView users={getUser} seller={idSellerParams} />}
                    </div>
                </div>
            </div>
        </div>
    );
}