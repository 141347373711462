export default function PrivacyView() {
    return (
        <>
            <div className="containerApp">
                <div className="container alignCenter">
                    <div className="row">
                        <div className="col-12 contenInit">
                            <h3>TÉRMINOS Y CONDICIONES:</h3>
                            <p>Los presentes términos y condiciones de uso de uso constituyen un contrato entre cualquier persona (en adelante, “Usuario” o, en plural “Usuarios”) que desee acceder y/o usar el servicio <strong>LEITEN OBRADOR</strong> y al uso de los sitios web https://leiten.com.ar, https://sinis.com.ar, https://idee.com.ar y https://barton.com.ar .

                            En estos Términos Generales se describen los derechos, responsabilidades y obligaciones del Usuario y de <strong>LEITEN OBRADOR</strong> al utilizar el servicio de procesamiento de pagos de Obrador Express y cualquier producto o servicio relacionado que ofrezca Obrador Express (cualquier de ellos, indistintamente, el “Servicio” y, conjuntamente, los “Servicios”, según corresponda).

                            El usuario debe leer, comprender y aceptar la totalidad de las condiciones establecidas en estas Condiciones Generales, incluyendo las políticas y principios incorporados a las mismas por referencia, previo a su registro como Usuario de <strong>LEITEN OBRADOR</strong>, Obrador Express y/o la utilización del Servicio. Al acceder y hacer uso del Servicio, el usuario acepta, para todos los efectos legales que puedan corresponder, sujetarse a los presentes términos y condiciones, de acuerdo a lo descripto a continuación:</p>

                            <h6>UTILIZACIÓN DEL SITIO</h6>
                            <p>El simple acceso al Sitio Web implica, por parte del Usuario, la aceptación total y sin modificaciones de los Términos y Condiciones y la información correspondiente tanto al Sitio Web como a cualquier material que contenga. El Sitio Web se ofrece condicionando su uso a la aceptación del Usuario sin modificación de los Términos y Condiciones y demás avisos aquí contenidos. El Usuario se podrá informar en todo momento acerca del tratamiento que el Servicio le otorga a sus datos personales, así como los procedimientos para el ejercicio de sus derechos relativos a sus datos personales, en la política de privacidad que se encuentra disponible en el sitio web.

                            Registro. Todo usuario que desee utilizar el servicio debe registrarse en <strong>LEITEN OBRADOR</strong> y aceptar estos términos de uso, incluso en caso de registración abreviada bajo la modalidad de “invitado”. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera (“Datos Personales”). El usuario asume el compromiso de actualizar los datos personales provistos por el y sus usuarios autorizados. Los usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los datos personales ingresados. Toda la información y los datos personales ingresados por el usuario tienen carácter de declaración jurada.

                            <strong>LEITEN OBRADOR</strong> se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información entregada por un usuario en materia de datos personales, así como de suspender temporal o definitivamente a aquellos usuarios cuyos datos no hayan podido ser confirmados.

                            <strong>LEITEN OBRADOR</strong> se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una cuenta <strong>LEITEN OBRADOR</strong>

                            (conforme es definida a continuación), en caso de detectar incongruencias o inconsistencias en la información provista por un usuario o en caso de detectar actividades sospechosas, sin que tal decisión genere para el usuario derecho a solicitar indemnización o resarcimiento alguno.

                            El usuario accederá a su cuenta <strong>LEITEN OBRADOR</strong> mediante el ingreso de su dirección de e-mail y clave de seguridad personal elegida. El usuario se obliga a mantener la confidencialidad de su clave de ingreso. En virtud de ello, el usuario será el único y exclusivo responsable por todas las operaciones efectuadas en su cuenta <strong>LEITEN OBRADOR</strong>. El usuario se compromete a notificar a <strong>LEITEN OBRADOR</strong> en forma inmediata y por medio idóneo y fehaciente, de cualquier uso no autorizado de su cuenta <strong>LEITEN OBRADOR</strong>, así como del ingreso o de intentos de ingreso por terceros no autorizados en la misma.

                            La Cuenta <strong>LEITEN OBRADOR</strong> es personal, única e intransferible, y será asignada a un solo usuario y está prohibida su venta, cesión o transferencia bajo ningún título. El Usuario no podrá permitir ni autorizar el uso de su Cuenta <strong>LEITEN OBRADOR</strong> por terceras personas. Cualquier referencia a dinero en <strong>LEITEN OBRADOR</strong> se entenderá hecha a la moneda de curso legal en la República Argentina, salvo en los casos que se indique expresamente otra moneda.

                            El usuario autoriza a <strong>LEITEN OBRADOR</strong> a hacer uso, para los fines que la aplicación fue creada, de toda la información compartida por el mismo, en el formato que fuere; haciéndose el Usuario responsable de la información brindada, y que la misma no viola derechos personales de terceros.</p>

                            <h6>PROPIEDAD INTELECTUAL:</h6>
                            <p>La tecnología, los desarrollos, fases de integración y toda la aplicación que conforma los servicios <strong>LEITEN OBRADOR</strong> son de propiedad exclusiva de <strong>LEITEN OBRADOR</strong>, incluyendo su funcionalidad, códigos, arquitectura, apariencia visual e implementaciones.

                            El servicio <strong>LEITEN OBRADOR</strong> será facilitado por medio de un entorno digital, para facilitar que el usuario tenga acceso a los servicios de la aplicación.

                            El usuario quedará irrevocablemente obligado a hacer buen uso del servicio <strong>LEITEN OBRADOR</strong>, debiendo cumplir las exigencias de seguridad. El usuario se obliga a utilizar el servicio <strong>LEITEN OBRADOR</strong> únicamente en su beneficio y con la finalidad de procesar transacciones propias de su giro comercial, teniendo estrictamente prohibido compartir o ceder la usabilidad, integraciones y demás componentes del servicio <strong>LEITEN OBRADOR</strong>, excepto mediante autorización escrita otorgada por este último.</p>

                            <h6>PROTECCION DE DATOS:</h6>
                            <p><strong>LEITEN OBRADOR</strong> no venderá, alquilará ni negociará con otras empresas la información personal de los usuarios salvo en las formas y casos establecidas en las políticas de privacidad. Estos datos serán utilizados para prestar el servicio <strong>LEITEN OBRADOR</strong>. Sin embargo, <strong>LEITEN OBRADOR</strong> podrá compartir dicha información

    con proveedores de servicios de valor agregado que se integren dentro del sitio mediante enlaces a otros sitios de internet, para atender necesidades de los usuarios y/o compradores relacionadas con los servicios que suministra <strong>LEITEN OBRADOR</strong>. Para mayor información, consulte las políticas de privacidad de <strong>LEITEN OBRADOR</strong> que regirán en forma supletoria para estos términos y condiciones. Toda la información personal transmitida se hace a través de una página de internet segura que protege y encripta la información. La información personal se almacena en servidores que mantienen altos estándares de seguridad. <strong>LEITEN OBRADOR</strong> siempre almacena los datos de las tarjetas de los compradores en forma encriptada y de acuerdo con las normas PCI_DSS, las cuales le serán aplicables a los usuarios en la medida en que almacenen datos de transacciones que no sean de su titularidad. <strong>LEITEN OBRADOR</strong> NO almacena el código de seguridad de las tarjetas, de modo que siempre se solicitará dicho código a los compradores en cada pago que realicen utilizando el servicio <strong>LEITEN OBRADOR</strong>.

    En cuanto a potenciales usuarios, <strong>LEITEN OBRADOR</strong> no almacenará nombres, direcciones postales, datos bancarios y/o documentos de identidad, ni ninguna otra información que permita identificar o haga identificable a una persona.

    hará sus mejores esfuerzos para mantener la confidencialidad y seguridad de que trata esta sección, pero no responderá por perjuicios que puedan derivar de la violación de dichas medidas por parte de terceros que utilicen las redes públicas o internet para acceder a dicha información o en los casos en que haya habido culpa o negligencia del usuario, y/o si el usuario hubiera otorgado permisos a terceros distintos de <strong>LEITEN OBRADOR</strong> para acceder a sus datos personales.</p>

                        <h6>CONDICIONES GENERALES DE CONTRATACIÓN:</h6>
                        <p><strong>Capacidad.</strong> Sólo podrán contratar los servicios de <strong>LEITEN OBRADOR</strong> aquellas personas que tengan capacidad legal para contratar y estén debidamente registrados como usuarios en <strong>LEITEN OBRADOR</strong>. No podrán registrarse ni utilizar los servicios menores de edad, personas que no tengan capacidad para contratar o aquellos usuarios que hayan sido suspendidos o inhabilitados del sistema de <strong>LEITEN OBRADOR</strong>, temporal o definitivamente.

    <strong>Declaraciones del usuario. Usos prohibidos.</strong> El usuario manifiesta que el objeto por el cual efectúa la contratación de los servicios <strong>LEITEN OBRADOR</strong> no infringe ninguna ley aplicable, ni será utilizado para brindar un servicio, venta o transmisión que está prohibida por la ley o las condiciones generales y/o cualquier tipo de actividad que pueda ser considerada fraudulenta o ilegal o sospechosa de serlo.

    El usuario no utilizará los servicios <strong>LEITEN OBRADOR</strong> para transmitir material que constituya un delito o bien que pueda dar lugar, directa o indirectamente a responsabilidades civiles, penales y/o que infrinjan estos términos y condiciones.

    Si <strong>LEITEN OBRADOR</strong> considera que hay una sospecha o indicio de la utilización de los servicios para alguna actividad prohibida por la ley y/o estos términos y condiciones, podrá rechazar, cancelar o suspender el uso de los mismos, y/o bloquear temporalmente

    el acceso y uso de una cuenta <strong>LEITEN OBRADOR</strong>, y/o la utilización o disponibilidad de funcionalidades y/o cancelar definitivamente una cuenta <strong>LEITEN OBRADOR</strong>. En tal caso, el usuario responderá por los daños y perjuicios que pueda haber ocasionado a <strong>LEITEN OBRADOR</strong> y sociedades vinculadas al mismo, filiales o subsidiarias, funcionarios y/o empleados a cuyos efectos <strong>LEITEN OBRADOR</strong> se reserva el derecho de iniciar las acciones judiciales o extrajudiciales que estime pertinentes.

    El usuario será exclusiva e ilimitadamente responsable por los perjuicios que su conducta pueda causar a <strong>LEITEN OBRADOR</strong> o a los restantes usuarios del servicio APP.

    <strong>Utilización del servicio por cuenta propia.</strong> Solamente se permite utilizar los servicios en nombre propio y no en representación y/o por cuenta de terceros. El usuario no revenderá los servicios a terceros.</p>

    <h6>POLÍTICA DE PRIVACIDAD</h6>
    <p>El presente Política de Privacidad establece los términos en que se usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. <strong>LEITEN OBRADOR</strong> está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.

    <strong>Información que es recogida</strong>

    Nuestro sitio web podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.

    <strong>Uso de la información recogida</strong>

    Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.

    <strong>LEITEN OBRADOR</strong> está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.

    <strong>Cookies</strong>

    Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.

    Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de los navegadores aceptan cookies automáticamente, pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.

    <strong>Enlaces a Terceros</strong>

    Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre el sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad, ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.

    <strong>Control de su información personal</strong>

    En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.

    Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.

    <strong>LEITEN OBRADOR</strong> Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};