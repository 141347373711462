import { Link } from "react-router-dom";
export default function HomeView() {

    return (
        <>
            <div className="container alignCenter marginTop">
                <h2>Bienvenido a la APP de Obrador Express</h2>
                <div className="row">
                    <div className="col-12">
                        <Link to="/cliente" >
                            <button className='btn btn-danger marginTop'>Quiero Alquilar</button>
                        </Link>

                    </div>
                    
                </div>
            </div>
        </>
    );
}
