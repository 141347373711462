export default function EmployeeNotLoggedView ({ users }) {
    return (
        <>
            <h3 className="marginTop">Sesión Finalizada</h3>
            <div className="contenInit marginTop">
                {users.map((user) => (
                    <div key={user.CodUsr}>
                        <p>Por seguridad, por favor, volvé a loguearte</p>
                        <h2 className="marginTop"><strong>{user.NomUsr}</strong></h2>
                        <p className="marginTop">con el botón Verde "Empezar"</p>
                    </div>
                ))}
            </div>
        </>
    );
}