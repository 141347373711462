import EvaluationEnvironment from "./EvaluationEnvironment";

export async function ValidarClientePorDocumento (TipoDoc, NroDoc) {
    const { appApiUrl, token } = EvaluationEnvironment();

    try {
        //const response = await fetch(`${appApiUrl}/Clientes/ValidarClientePorDocumento/TipoDoc/${TipoDoc}/NroDoc/${NroDoc}`, {
        const response = await fetch(`https://erpleiten.dnscheck.com.ar/ws/ValidarClientePorDocumento?TipDoc=${TipoDoc}&NroDoc=${NroDoc}`, { 
            
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return (data);
    } catch (error) {
        console.error('Error fetching currency:', error);
        return null;
    }
}

export async function IniciarRegistroClientePorDocumento(TipoDoc, NroDoc, Nombre, telefono, email, adjunto) {    
    const url = `https://apptesting.leiten.dnscheck.com.ar/Clientes/IniciarRegistroClientePorDocumento?TipoDoc=${encodeURIComponent(TipoDoc)}&NroDoc=${encodeURIComponent(NroDoc)}&Nombre=${encodeURIComponent(Nombre)}&Telefono=${encodeURIComponent(telefono)}&Mail=${encodeURIComponent(email)}`;

    let jsonData = null;    

    if (adjunto) {
        try {
            const adjuntoBase64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result.split(',')[1]); // Base64 data
                reader.onerror = reject;
                reader.readAsDataURL(adjunto); // Convert file to Base64
            });

            jsonData = [{
                "nombreFile": adjunto.name,
                "data": adjuntoBase64,
            }];
        } catch (error) {
            console.error('Error al convertir el archivo a base64:', error);
            return; // Salir si hay un error en la conversión
        }
    }
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': '12345', 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData)  // Convertir el array a JSON
        });

        if (response.ok) {
            return response;
        } else {
            const errorText = await response.text();  // Leer la respuesta del error si está disponible
            console.error('Error en la solicitud:', response.status, response.statusText, errorText);
            return response;
        }
    } catch (error) {
        console.error('Error al enviar la solicitud:', error);
        throw error;
    }
}



