import styled from "styled-components";

export default function Header() {

  return (
    <HeadesStyle className="alignCenter">
      <div className="containerImgBanner">
        <img src="/assets/img/headerLeiten.jpeg" alt="header" />
      </div>
    </HeadesStyle>
  );
}


const HeadesStyle = styled.header`
  .containerImgBanner{
    width: 100%;
  }
  img{
    width:125%;
    margin-left: -25%;
  }
`
