import { Outlet } from 'react-router-dom';
import Header from '../partials/Header';

const LayoutSimple = (props) => {

    return(
        <>
            <div id='home'>
                <Header/>
            </div>
            <Outlet/>
        </>
    )
}

export default LayoutSimple;