export default function EvaluationEnvironment (){
    let appApiUrl = "";
    let token = "";

    if (process.env.REACT_APP_ENV === "prod") {
        appApiUrl = process.env.REACT_APP_API_URL
        token = process.env.REACT_APP_API_KEY
    } else {
        appApiUrl = process.env.REACT_APP_API_TEST_URL
        token = process.env.REACT_APP_API_TEST_KEY
    }

    return { appApiUrl, token };
    
}