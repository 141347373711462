import { useSearchParams } from "react-router-dom";

export default function QrView(){

    const [searchParams] = useSearchParams();
    
    const idEtiParams = searchParams.get ('ideti');
    const idTestParams = searchParams.get ('idtest');
    const idProdParams = searchParams.get ('idprod');
    
    return(
        <div className="contenInit">
            <h3 className="alignCenter">Escaneaste el QR: </h3>
            <br />
            <h2 className="alignCenter">
                {idTestParams !== null ? `La Etiqueta llego desde Testing y es: ${idTestParams}` : idProdParams === null ? `La Etiqueta: ${idEtiParams}` : `El Producto: ${idProdParams}`}
            </h2>
        </div>
    )
}