export default function EmployeeLoggedView ({ users, seller }) {
    return (
        <div className="contenInit">                
            {users.map((user) => (
                <div key={user.CodUsr}>
                    <p>Hola {seller === null ? '' : 'Vendedor'}</p>
                    <h2>{user.NomUsr} !!!</h2>
                </div>
            ))}
        </div>
    );
}