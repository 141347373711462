import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Spinner from '../../../component/spinner/Spinner';
import { ValidarClientePorDocumento } from "../../../services/CustomersServices";
import { MultiPF, OnePf, Pj, Cl, Error } from './NewCustomerCases';

export default function HomeViewCustomers() {
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [valorDocumento, setValorDocumento] = useState('');
    const [getCliente, setGetCliente] = useState();
    const [loading, setLoading] = useState(false);

    // useEffect que escucha cambios en tipoDocumento y valorDocumento para hacer la consulta
    useEffect(() => {
        const fetchCliente = async () => {
            if (
                (tipoDocumento === 'DNI' && valorDocumento.length === 8) ||
                (tipoDocumento === 'CUIT' && valorDocumento.length === 11)
            ) {
                setLoading(true); // Inicia el spinner
                try {
                    const userCliente = await ValidarClientePorDocumento(tipoDocumento, valorDocumento); //tipoDocumento, valorDocumento
                    setGetCliente(userCliente);
                    
                } catch (error) {
                    console.error("Error fetching client:", error);
                    setGetCliente({ error: true });
                } finally {
                    setLoading(false); // Detiene el spinner cuando finaliza la consulta
                }
            } else {
                setGetCliente(null); // Limpia el estado si los valores no son válidos
            }
        };
        
        fetchCliente();
    }, [tipoDocumento, valorDocumento]);

    const handleSelectChange = (e) => {
        setTipoDocumento(e.target.value);
        setValorDocumento(''); // Reinicia el valor del documento al cambiar el tipo de documento
    };

    const handleInputChange = (e) => {
        const valor = e.target.value;
        if (valor.length <= 11) {  // Limita el input a 11 caracteres
            setValorDocumento(valor);
        }
    };

    const renderContent = () => {
        if (getCliente?.error) {
            return <Error />;
        }
        if (getCliente) {

            if (getCliente.length >= 2 && getCliente.length <= 4) {
                return <MultiPF clientes={getCliente} />;
            } if (getCliente.length === 1 && getCliente[0].TipoReg === "PF" ) {
                return <OnePf cliente={getCliente[0]} TipoDoc={tipoDocumento} NroDoc={valorDocumento} />;
            } if (getCliente.length === 1 && getCliente[0].TipoReg === "PJ") {
                return <Pj cliente={getCliente[0]}/>;
            } if (getCliente.length === 1 && getCliente[0].TipoReg === "CL") {
                return <Cl/>;
            }
        } else {
            <Error/>
        }
    };

 
    return (
        <StyledHomeViewCustomers>
            <div className='container'>
                <div className="row">
                    <div className="col-12">
                        <div className="col-12 contenInit marginTop">
                            
                                <label htmlFor="tipoSelect" className="form-label">Tipo de Documento:</label>
                                <select
                                    id="tipoSelect"
                                    className="form-select"
                                    value={tipoDocumento}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione</option>
                                    <option value="DNI">DNI</option>
                                    <option value="CUIT">CUIL / CUIT</option>
                                </select>
                                
                                <label htmlFor="tipoValue" style={{marginTop:'1rem'}}>Número: </label>
                                <input
                                    id="tipoValue"
                                    placeholder="sin guion o puntos"
                                    type="number"
                                    className="form-control"
                                    value={valorDocumento}
                                    onChange={handleInputChange}
                                    maxLength="11"
                                />

                                {loading && <Spinner styleMarginTop={styleMarginTop}/>}
                                {renderContent()}
                                
                          
                        </div>
                    </div>
                </div>
            </div>
        </StyledHomeViewCustomers>
    );
};

const styleMarginTop = {
    marginTop: "3rem",
}

const StyledHomeViewCustomers = styled.div`
    background-color: var(--colorPrimario);
    height: auto;

    .containerNovedades{
        width: 100%;
    }
    .imagen{
        color: #fff;
        background-color: var(--colorCuarto);
        width: 100%;
        height: 300px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    button{
        margin: 3rem;
    }
`;
