import React, { useState } from 'react';
import { IniciarRegistroClientePorDocumento } from '../../../services/CustomersServices';
import Modal from '../../../component/modal/Modal';

const MultiPF = ({ clientes }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleSelect = (index) => {
        setSelectedItem(index);
    };

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <h3>Resultados:</h3>
                    {/* <form onSubmit={handleSubmit}> */}
                        {clientes.map((item, index) => (
                            <div className='row' key={index}>
                                <div className="col-10">
                                    <label htmlFor={`item-${index}`}>
                                        <p>Nombre: {item.nombre}</p>
                                        <p>Dirección: {item.direccionCompleta}</p>
                                        {/* Otros campos si son necesarios */}
                                    </label>
                                </div>

                                <div className="col-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <input
                                        type="radio"
                                        id={`item-${index}`}
                                        name="selectedItem"
                                        value={index}
                                        checked={selectedItem === index}
                                        onChange={() => handleSelect(index)}
                                    />
                                </div>
                            </div>
                        ))}
                        <button type="submit" className='btn btn-danger' style={{display: 'flex', margin: '0 auto'}}>Enviar</button>
                    {/* </form> */}

                </div>
            </div>
        </div>
    );
};

const OnePf = ({ cliente, TipoDoc, NroDoc }) => {

    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Obtener los valores de los campos de teléfono, email y archivo adjunto
        const telefono = document.getElementById('telefono').value;
        const email = document.getElementById('email').value;
        const cliente = document.getElementById('cliente').value;
        const clienteCodificado = encodeURIComponent(cliente);
        const adjunto = document.getElementById('adjunto').files[0]; // Captura el archivo adjunto

        try {
            const response = await IniciarRegistroClientePorDocumento(TipoDoc, NroDoc, clienteCodificado, telefono, email, adjunto);

            if (response.ok) {
                setMessage('Registro exitoso');
            } else {
                setMessage('Hubo un error al enviar los datos');
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            setMessage('Error al enviar la solicitud');
        }
    };

    return (
        <>
        <form onSubmit={handleSubmit}>
            <label style={{ marginTop: '1rem' }} htmlFor="cliente">Nombre:</label>
            <input className="form-control" type="text" id="cliente" value={cliente.Nombre} readOnly/>

            <label style={{ marginTop: '1rem' }} htmlFor="cuil">Cuil:</label>
            <input className="form-control" type="text" id="cuil" value={cliente.NroDoc} readOnly />

            <label style={{ marginTop: '1rem' }} htmlFor="telefono">Télefono:</label>
            <input className="form-control" type="number" id="telefono" />

            <label style={{ marginTop: '1rem' }} htmlFor="email">Email:</label>
            <input className="form-control" type="email" id="email" />

            <label style={{ marginTop: '1rem' }} htmlFor="adjunto">Adjuntar Documento:</label>
            <input type="file" className='form-control' id="adjunto"/>

            <p style={{ marginTop: '1rem' }}>Dirección (la api post no me pide obligatorio): {cliente.DireccionCompleta}</p>

            <button type="submit" className="btn btn-danger" style={{ display: 'flex', margin: '0px auto' }}>Enviar</button>
            
            {/* Mostrar mensaje de éxito o error */}
            {message && <p className="text-success" style={{margin: '1rem auto'}}>{message}</p>}
        </form>

        </>
    );
};

const Pj = ({ cliente }) => (
    <>
        <h3>Persona Juridica:</h3>
        <p>Empresa: {cliente.Nombre}</p>
        <p>Dirección: {cliente.DireccionCompleta}</p>
    </>
);

const Cl = () => (
    <div>
        <h3>El cliente ya existe.</h3>
    </div>
);

const Error = () => (
    <div>
        <h3>Error al obtener los datos.</h3>
    </div>
);

export { MultiPF, OnePf, Pj, Cl, Error };
